<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              height="24"
              width="36"
              :src=" isDark ? appLogoImageDark : appLogoImage"
              alt="logo"
            />
          </span>
          <h2 class="brand-text pl-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app

    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      appName,
      appLogoImage,
      appLogoImageDark,
      skin, 
      isDark
    }
  },
}
</script>

<style>

</style>
