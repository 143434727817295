











































































































import { Component, Vue } from "vue-property-decorator";
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Consigne } from "@/api/models/dossiers/consignes";
@Component({
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
  },
})
export default class NotificationDropdown extends Vue {
  perfectScrollbarSettings = {
    maxScrollbarLength: 60,
    wheelPropagation: false,
  };

  get listeConsignes() {
    return this.$store.getters["consignes/consignes"] as PaginatedList<Consigne>;
  }  

  get nonLuesCount() {
    return this.listeConsignes?.items?.filter(x => !x.lue && !x.traitee).length ?? 0;
  }

  async created() {
    await this.loadNotifications();
    setInterval(this.loadNotifications, 300000)
  }

  async loadNotifications() {
    if (this.$store?.state?.user?.user?.uid) {
      this.$store.dispatch('consignes/fetchConsigne')
    }
  }

  refresh(id: string) {
    this.$router.push({
      name: 'consignes-details',
      params: { id: id }
    })
  }
}
