<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <bookmarks /> -->
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <charte-graphique-droplet/>         
      <search-bar/>
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <cart-dropdown /> -->
      <notification-dropdown v-if="canViewHorizontalNavMenuLink({rights: ['CONSIGNES']})"  ref="notification-dropdown" />
      <b-nav-item @click="goToChangelogs()">
        <feather-icon
            icon="FileTextIcon"
            size="21"
          />
      </b-nav-item>
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import CharteGraphiqueDroplet from './components/CharteGraphiqueDroplet.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import useUserDroits from '@/auth/useUserDroits'
import { ChangelogApplication } from "@/api/models/enums/changelogApplication";
import appSettings from "@/appSettings";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    CharteGraphiqueDroplet,
    UserDropdown,
    BNavItem
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup(props){
    const { canViewHorizontalNavMenuLink } = useUserDroits()
    return {
      canViewHorizontalNavMenuLink
    }
  },
  methods: {
    goToUserPreferences() {
        this.$router.push({ name: 'preferences-utilisateur' })
    },
    goToCharteGraphique() {
        this.$router.push({ name: 'charte-graphique' })
    },
    async countUnreadChangelogs() {
      await this.$http.myressif.unreadChangelogs
        .countUnread(ChangelogApplication.DAISY, appSettings.getTenantId())
        .then((response) => {
          if (response > 0) {
            const h = this.$createElement

            var button = h(
              'button', 
              { 
                on: { 
                  click: async () => {
                    await this.$http.myressif.unreadChangelogs
                      .post(ChangelogApplication.DAISY, appSettings.getTenantId())
                      .then((response) => {
                        this.$bvToast.hide("toast-changelogs");
                      })
                  } 
                }, 
                class: 'close ml-auto mb-1', 
                id: 'close-btoast-button', 
                style: "font-size: 20px; text-shadow: none; margin-bottom: 2px !important;" 
              }, 
              'x')

            const vNodesTitle = h(
              'div',
              { class: ['d-flex', 'flex-grow-1', 'align-items-baseline'] },
              [
                h('strong', { class: 'mr-2' }, 'Mise à jour du logiciel'),
                button
              ]
            )

            if (response == 1) {
              this.$bvToast.toast('Cliquer pour voir la nouvelle mise à jour.', {
                title: [vNodesTitle],
                variant: 'success',
                to: 'changelogs',
                autoHideDelay: 900000,
                noCloseButton: true,
                id: "toast-changelogs"
              })
            } else {
              this.$bvToast.toast('Cliquer pour voir les ' + response + ' nouvelles mises à jour.', {
                title: [vNodesTitle],
                variant: 'success',
                to: 'changelogs',
                autoHideDelay: 900000,
                noCloseButton: true,
                id: "toast-changelogs"
              })
            }

          }
        });
    },
    goToChangelogs() {
        this.$router.push({ name: 'changelogs' })
    },
  },
  async created() {
    await this.countUnreadChangelogs()
    setInterval(async () => await this.countUnreadChangelogs(), 900000);
  }

}
</script>
