<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark' , updateUserPreference()">
    <feather-icon
      size="21"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'
import store from '@/store'

export default {
  data() {
    return {
      themeColors: '',
    }
  },
  components: {
    BNavItem,
  },
 created() {
    this.themeColors = store.state.user.user_preferences.find((value) => value.key == 'theme');
    if(this.themeColors){
      store.commit('appConfig/UPDATE_SKIN', this.themeColors.value)
    } 
  },
  methods: {
    updateUserPreference(){   
      store.dispatch('user/updateUserPreference', { id:this.themeColors.id, value: this.skin, key : this.themeColors.key});
    },    
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')
  
    return { skin, isDark }
  },
}
</script>
