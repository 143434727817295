export default [
  {
    header: 'Statistiques',
    icon: 'BarChart2Icon',
    // rights: [
    //   'STATISTIQUES'
    // ],
    children: [
      // {
      //   title: 'Générales',
      //   icon: 'GlobeIcon',
      //   route: 'search-statistiques-generales',
      //   // rights: [
      //   //   'STATISTIQUES'
      //   // ],
      //   // notFonctions: [
      //   //   'CRL', 'RHU'
      //   // ],
      // },
      // {
      //   title: 'Dossiers instruits',
      //   icon: 'FolderIcon',
      //   route: 'search-statistiques-dossiers-instruits',
      //   // rights: [
      //   //   'STATISTIQUES'
      //   // ],
      //   // notFonctions: [
      //   //   'CRL', 'RHU'
      //   // ]
      // },
      // {
      //   title: 'CRL',
      //   icon: 'HexagonIcon',
      //   route: 'search-statistiques-crl',
      //   // rights: [
      //   //   'STATISTIQUES'
      //   // ],
      //   // fonctions: [
      //   //   'CRL', 'RHU', 'ADMNAT'
      //   // ]
      // },
      // {
      //   title: 'Facturation',
      //   icon: 'HexagonIcon',        
      //   route: 'search-statistiques-facturation',
      //   // rights: [
      //   //   'STATISTIQUES'
      //   // ],
      // },
      {
        header: 'RA automatisés',
        icon: 'BarChart2Icon',
        route: 'rapport-activites',
        title: 'Rapport d\'activités',
        rights: [
          "OPTIONS",
          "UTILISATIONRA"
        ],
      },
    ],
  },
]
