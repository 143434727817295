export default [
    {
      header: 'Divers',
    },
    {
      title: 'Documentation',
      icon: 'DownloadIcon',        
      route: 'documents-telecharger',
    },
  ]
  