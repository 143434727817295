export default [
  {
    header: 'Administration',
    icon: 'SettingsIcon',
    rights: [
      'ADMINISTRATION',
      'EQUIPES',
      'ADMINISTRATION_ENTREPRISE',
    ],
    children: [
      {
        title: 'Gérer les entreprises',
        icon: 'BriefcaseIcon',
        route: 'admin-entreprises',
        rights: [
          'ADMINISTRATION_ENTREPRISE',
        ],
      },
      {
        title: 'Gérer les utilisateurs',
        icon: 'UserIcon',
        route: 'admin-profils',
        rights: [
          'ADMINISTRATION',
        ],
      },
      {
        title: 'Gérer les équipes consignes',
        icon: 'UsersIcon',
        route: 'admin-equipes',
        rights: [
          'EQUIPES',
        ],
      },
      {
        title: 'Gérer les filtres de rapports d\'activité',
        icon: 'FilterIcon',
        route: 'admin-filtres-ra',
        rights: [
          'OPTIONS',
          'ADMINISTRATIONRA'
        ],
      },
      {
        title: 'Gérer les templates de rapport d\'activité',
        icon: 'BarChart2Icon',
        route: 'admin-rapport-activite',
        rights: [
          'OPTIONS',
          'ADMINISTRATIONRA'
        ],
      },
    ],
  },
]
