export default [
    {
      header: 'Suivi entreprise',
      title: 'Suivi entreprise',
      icon: 'BriefcaseIcon',
      route: 'search-suivi-entreprise',
      rights: [
        "ENTREPRISE",
        "ENTREPRISE_MANAG_TOTAL"
      ]
    }
  ]
  