<template>
  <b-nav-item v-show="$can(droits.OPTIONS)" @click="goToCharteGraphique()">
    <feather-icon
      size="21"
      icon="DropletIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import { DroitsEnum } from '@/api/models/enums/droitsEnum'

export default {
  data() {
    return {
      droits: {}
    }
  },
  components: {
    BNavItem,
  },
 created() {
    this.themeColors = store.state.user.user_preferences.find((value) => value.key == 'theme');
    if(this.themeColors){
      store.commit('appConfig/UPDATE_SKIN', this.themeColors.value)
    } 
  },
  methods: {
    goToCharteGraphique() {
        this.$router.push({ name: 'charte-graphique' })
    }  
  },
  setup() {},
  created () {
    this.droits = DroitsEnum
  }
}
</script>
