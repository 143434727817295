export default [
  {
    header: 'Suivi de dossier',
    rights: [
      "DOSSIER_INFO_GENERAL",
    ],
  },
  {
    title: 'Rechercher un dossier',
    icon: 'SearchIcon',
    route: 'accompagnement-individuel',
    rights: [
      "DOSSIER_INFO_GENERAL",
    ],
  },
  {
    title: 'Nouveau dossier',
    icon: 'UserPlusIcon',
    route: 'create-accompagnement-individuel',
    rights: [
      "DOSSIER_INFO_GENERAL",
    ],
  },
  {
    title: 'Importer dossiers',
    icon: 'FilePlusIcon',
    route: 'import-dossiers-accompagnement-individuel',
    rights: [
      "DOSSIER_INFO_GENERAL",
    ],
  },
  {
    title: 'Recherche avancée',
    icon: 'UsersIcon',
    route: 'list-accompagnement-individuel',
    rights: [
      "DOSSIER_INFO_GENERAL",
    ],
  },

  {
    header: 'Suivi entreprise',
    rights: [
      'ENTREPRISE',
      "ENTREPRISE_MANAG_TOTAL"
    ],
  },
  {
    title: 'Recherche',
    icon: 'BriefcaseIcon',
    route: 'search-suivi-entreprise',
    rights: [
      'ENTREPRISE',
      "ENTREPRISE_MANAG_TOTAL"
    ],
  }
]