



















































































































































import {
  BFormInput, BLink, BImg, BAvatar, BButton, BSpinner
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { Component, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    BButton,
    BSpinner,
    VuePerfectScrollbar,
  },
})
export default class SearchBar extends Vue {
  searchLoading = false

  showSearchBar = false
  searchResult = [] 
  searchQuery = ""
  currentSelected: any = null

  timeout: any = null;

  perfectScrollbarSettings = {
    maxScrollbarLength: 100,
  }

  selectResult(dossierId: string) {
    this.showSearchBar = false;
    this.$router.push({ name: 'details-accompagnement-individuel', params: { id: dossierId } }).catch(()=>{});
  }

  @Watch("searchQuery")
  searchDossiers() {
    this.searchLoading = true
    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      await this.$http.ressifnet.dossiers
        .search({
          // nomPersonne: this.searchQuery,
          nomNumeroPersonne: this.searchQuery,
          pageNumber: 1,
          pageSize: 10,
          withoutCount: true
        })
        .then((response: any) => {
          this.searchResult = response.items;
          this.searchLoading = false;
        })
    }, 300)
  }
}
