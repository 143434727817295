export default [
    {
      header: 'Suivi de dossier',
      icon: 'UserIcon',
      children: [
        {
          title: 'Rechercher un dossier',
          icon: 'SearchIcon',
          route: 'accompagnement-individuel',
          rights: [
            "DOSSIER_INFO_GENERAL",
          ],
        },
        {
          title: 'Importer dossiers',
          icon: 'FilePlusIcon',
          route: 'import-dossiers-accompagnement-individuel',
          rights: [
            "DOSSIER_INFO_GENERAL",
          ],
        },
        {
          title: 'Nouveau dossier',
          icon: 'UserPlusIcon',
          route: 'create-accompagnement-individuel',
          rights: [
            "DOSSIER_INFO_GENERAL",
          ],
        },
        {
          title: 'Création rapide',
          icon: 'FastForwardIcon',
          route: 'create-accompagnement-individuel-rapide',
          rights: [
            "DOSSIER_INFO_GENERAL",
          ],
        },
        {
          title: 'Recherche avancée',
          icon: 'UsersIcon',
          route: 'list-accompagnement-individuel',
          rights: [
            "DOSSIER_INFO_GENERAL",
          ],
        },
      ],
    },
  ]
  